import * as React from "react"

export default function() {
    return <>
            <div className="footer">
              <ul className="menu">
                <li>
                  <a href="#datareporter">Technology</a>
                </li>
                <li>
                  <a href="#resources">Resources</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
              <div className="copy is-desktop">© Copyright DataReporter 2022</div>
              <ul className="menu-right">
                {/* <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy </a>
                </li> */}
              </ul>
              <div className="copy is-mobile">© Copyright DataReporter 2022</div>
            </div>
           </>;
}
